import InfoCircle from '$icons/info-circle.svg';
import { Product } from '$templates/blocks/components/M140ProductsList/model';
import NextLink from 'next/link';
import { useMemo } from 'react';
import { Icon, Image, Tooltip } from '~/shared/components';
import { ColorDots } from '~/shared/components/ColorDots/ColorDots';
import { Frame, useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { ProductOptions } from '../productOptions';
import { getValueTranslation } from '../utils';
import { mapProductToBasicProps } from '../utils/mapProductToBasicProps';
import {
    StyledImageContainer,
    StyledListItemColumn,
    StyledListItemText,
    StyledProductListItem,
} from './styled';

export type ProductListItemProps = {
    product: Product;
    productPageUrl: string;
    showPrice: boolean;
    onQuoteButtonClick?: (product: Product) => void;
};

const getTooltip = (text: string) => {
    if (!text) return;
    return (
        <Tooltip content={text} delayDuration={0} contentWidth={370}>
            <Icon>
                <InfoCircle />
            </Icon>
        </Tooltip>
    );
};

export const fallbackText = '-';

export const ProductListItem = ({
    product,
    productPageUrl,
    showPrice,
    // @ts-expect-error temporarily disabled
    // eslint-disable-next-line unused-imports/no-unused-vars
    onQuoteButtonClick,
}: ProductListItemProps) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();

    const {
        spaceRequired,
        price,
        url,
        imageUrl,
        colors,
        userCapacity,
        dimensionsText,
        fallHeightText,
    } = useMemo(() => {
        return mapProductToBasicProps({
            product,
            productPageUrl,
            frame: frame as Frame,
            translate,
        });
    }, [product, productPageUrl, frame, translate]);

    const productId = useMemo(() => product.productId, [product.productId]);

    const ageGroupText = useMemo(() => {
        return product?.data?.AgeGroup?.value;
    }, [product?.data]);

    return (
        <StyledProductListItem showPrice={showPrice ? 'True' : 'False'}>
            <StyledListItemColumn
                justifyContent="start"
                flexDirection="column"
                noPadding
                textAlign="left"
            >
                {imageUrl && (
                    <NextLink href={url as string} prefetch={false} legacyBehavior>
                        <a href={url}>
                            <StyledImageContainer>
                                <Image
                                    sizes="100px"
                                    quality="70"
                                    objectFit="contain"
                                    layout="fill"
                                    src={imageUrl}
                                    alt={''}
                                />
                            </StyledImageContainer>
                        </a>
                    </NextLink>
                )}
            </StyledListItemColumn>

            <StyledListItemColumn
                justifyContent="start"
                textAlign="left"
                flexDirection="column"
                alignItems={'flex-start'}
            >
                <NextLink href={url as string} prefetch={false} legacyBehavior>
                    <a href={url}>
                        <StyledListItemText variant={'body'}>
                            {product.displayName}
                        </StyledListItemText>
                        <StyledListItemText variant="caption" colorVariant="grey">
                            {product.productId}
                        </StyledListItemText>
                    </a>
                </NextLink>
            </StyledListItemColumn>

            <StyledListItemColumn
                justifyContent="start"
                alignItems="flex-start"
                flexDirection="column"
            >
                <ColorDots colors={colors} maxColors={5} />
            </StyledListItemColumn>

            <StyledListItemColumn
                justifyContent="start"
                alignItems="flex-start"
                flexDirection="column"
            >
                <StyledListItemText variant={'body'}>
                    {ageGroupText ?? fallbackText}
                </StyledListItemText>
            </StyledListItemColumn>

            <StyledListItemColumn
                justifyContent="start"
                alignItems="flex-start"
                flexDirection="column"
            >
                <StyledListItemText variant={'body'}>
                    {userCapacity ?? fallbackText}
                </StyledListItemText>
            </StyledListItemColumn>

            <StyledListItemColumn
                justifyContent="start"
                alignItems="flex-start"
                flexDirection="column"
            >
                <StyledListItemText variant={'body'}>
                    {spaceRequired ?? fallbackText}
                </StyledListItemText>
                <StyledListItemText variant="caption" colorVariant="grey">
                    {dimensionsText}
                </StyledListItemText>
            </StyledListItemColumn>

            <StyledListItemColumn
                justifyContent="start"
                alignItems="flex-start"
                flexDirection="column"
            >
                <StyledListItemText variant={'body'}>
                    {fallHeightText
                        ? getValueTranslation(translate, 'MaxFallHeight', `${fallHeightText}`)
                        : fallbackText}
                </StyledListItemText>
            </StyledListItemColumn>

            {showPrice && (
                <StyledListItemColumn
                    justifyContent="start"
                    alignItems="flex-start"
                    flexDirection="row"
                    textAlign="left"
                >
                    <StyledListItemText variant={'body'} style={{ marginRight: '4px' }}>
                        {price ?? translate('Kompan.Commerce.EmptyPriceFallbackText')}
                    </StyledListItemText>
                    {price && getTooltip(translate('Kompan.ProductDetail.PriceDisclaimer'))}
                </StyledListItemColumn>
            )}

            <StyledListItemColumn justifyContent="start" alignItems="flex-start">
                {productId && <ProductOptions product={product} variant="transparent" />}
            </StyledListItemColumn>

            {/* <StyledListItemColumn justifyContent="start">
                {onQuoteButtonClick && (
                    <StyledQuoteButton variant="Red" onClick={() => onQuoteButtonClick(product)}>
                        {translate('Kompan.ProductDetail.GetQuote')}
                    </StyledQuoteButton>
                )}
            </StyledListItemColumn> */}
        </StyledProductListItem>
    );
};
