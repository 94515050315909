import { Icon, Tooltip } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import { getColumnTitleTranslation, getColumnTooltipTranslation } from '../utils';
import {
    StyledColumnTooltip,
    StyledHeadlineButton,
    StyledHeadlineColumn,
    StyledHeadlineText,
    StyledProductListHeadline,
} from './styled';
import InfoCircle from '$icons/info-circle.svg';
import ArrowDown from '$icons/arrow-down.svg';
import ArrowUp from '$icons/arrow-up.svg';
import { usePage } from '~/templates/pages';
import { SortFacetsType } from '../../model';
import { useMemo, useState } from 'react';

const getTooltip = (text: string) => {
    if (!text) return;
    return (
        <Tooltip content={text} delayDuration={0} contentWidth={370}>
            <StyledColumnTooltip>
                <Icon>
                    <InfoCircle />
                </Icon>
            </StyledColumnTooltip>
        </Tooltip>
    );
};

type ProductListHeadline = {
    onSortingChange: (sortKey: string) => void;
    sorting: SortFacetsType;
    showPrice: boolean;
};

type ArrowType = {
    activeKey: string | null;
    keyName: string;
    sortOrder: SortFacetsType['sortOrder'];
    showSortIcon: boolean;
};

const Arrow = ({ activeKey, keyName, sortOrder, showSortIcon }: ArrowType) => {
    return (
        <Icon size={'sm'}>
            {activeKey === keyName && sortOrder === 'desc' ? (
                <ArrowDown title={'Arrow Down'} />
            ) : showSortIcon ? (
                <ArrowUp title={'Arrow Up'} />
            ) : (
                ''
            )}
        </Icon>
    );
};

type SortingList = {
    [key: string]: string;
};

export const ProductListHeadline = ({
    onSortingChange,
    sorting,
    showPrice,
}: ProductListHeadline) => {
    const { translate } = useTranslation();

    const { sortKey: activeKey, sortOrder } = sorting;
    const { market = 'int', culture = 'en' } = usePage();
    const [showSortIcon, setShowSortIcon] = useState(true);

    const keyNameList = useMemo(() => {
        const list = [
            'ProductName',
            'Colors',
            'AgeGroup',
            'UserCapacity',
            'Space',
            'MaxFallHeight',
        ];

        if (showPrice) list.push('Price');

        return list;
    }, [showPrice]);

    const sortingList: SortingList = useMemo(
        () => ({
            ProductName: `DisplayName`,
            AgeGroup: `AgeGroup_plp_from_${culture}-${market}`,
            UserCapacity: 'UserCapacity',
            MaxFallHeight: `MaxFallHeight_${culture}-${market}`,
            Space: `SafetyZoneArea_${culture}-${market}`,
            Price: 'listPrice',
        }),
        [market, culture],
    );

    const handleOnClick = (sortKey: string) => {
        if (sortOrder === 'desc' && activeKey === sortKey) {
            setShowSortIcon(false);
        } else {
            setShowSortIcon(true);
        }
        onSortingChange(sortKey);
    };

    return (
        <StyledProductListHeadline showPrice={showPrice ? 'True' : 'False'}>
            <StyledHeadlineColumn>{/* Intentional Empty column */}</StyledHeadlineColumn>

            {keyNameList.map((keyName) => {
                return sortingList[`${keyName}`] ? (
                    <StyledHeadlineColumn justifyContent={'flex-start'}>
                        <StyledHeadlineButton
                            colorVariant="grey"
                            onClick={() => handleOnClick(sortingList[`${keyName}`])}
                            active={activeKey === sortingList[`${keyName}`] ? true : false}
                            onMouseLeave={() => setShowSortIcon(true)}
                        >
                            {getColumnTitleTranslation(translate, keyName)}
                            {getTooltip(getColumnTooltipTranslation(translate, keyName))}
                            <Arrow
                                activeKey={activeKey}
                                keyName={sortingList[`${keyName}`]}
                                sortOrder={sortOrder}
                                showSortIcon={showSortIcon}
                            />
                        </StyledHeadlineButton>
                    </StyledHeadlineColumn>
                ) : (
                    <StyledHeadlineColumn justifyContent={'flex-start'}>
                        <StyledHeadlineText colorVariant="grey">
                            {getColumnTitleTranslation(translate, keyName)}
                            {getTooltip(getColumnTooltipTranslation(translate, keyName))}
                        </StyledHeadlineText>
                    </StyledHeadlineColumn>
                );
            })}
        </StyledProductListHeadline>
    );
};
