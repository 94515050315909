import React, { useEffect, useState } from 'react';
import { StyledProductCategoryList, StyledProductCategoryListContainer } from './styled';
import { useProductsStore } from '../../hooks';
import { useTranslation } from '~/shared/utils/translation';
import { getCurrentLocale } from '~/templates/pages/utils';
import { ProductCategoryListButton } from '../ProductCategoryListButton';
import { ProductCategoryQueryItem } from '../../requests';
import { usePage } from '~/templates/pages';

export type ProductCategoryListProps = {
    scopedCategories?: string[];
    showEmptyCategories?: boolean;
};

export const ProductCategoryList = ({
    scopedCategories = [],
    showEmptyCategories = true,
}: ProductCategoryListProps) => {
    const { selectedCategories, setSelectedCategories, availableCategories, facets } =
        useProductsStore();
    const { translate } = useTranslation();
    const { breadcrumb } = usePage();

    const locale = getCurrentLocale();

    const [categoryList, setCategoryList] = useState<ProductCategoryQueryItem[]>([]);
    const singleToggleMode = true;

    useEffect(() => {
        let categoriesCollection: ProductCategoryQueryItem[] = [];
        if (scopedCategories?.length) {
            scopedCategories.forEach((scopedCategoryId) => {
                const categories =
                    availableCategories?.allCategories
                        .find((category) => category.categoryId == scopedCategoryId)
                        ?.childCategories?.filter((x) => x.categoryId.length) || [];
                categoriesCollection = [...categoriesCollection, ...categories];
            });
        } else {
            // Default show all L2 categories
            categoriesCollection = availableCategories.l2 ? [...availableCategories.l2] : [];
        }

        if (!showEmptyCategories) {
            categoriesCollection = categoriesCollection.filter((cat) =>
                isCategoryOptionAvailable(cat.categoryId),
            );
        }
        setCategoryList(categoriesCollection);
    }, [scopedCategories, availableCategories]);

    const isCategoryOptionSelected = (value: string) => {
        return selectedCategories.includes(value);
    };

    const isCategoryOptionAvailable = (categoryId: string) => {
        const categoryFacet = facets.find((facet) => {
            return facet.attribute == 'Category';
        });
        // If we don't have access to facet, assume it's available.
        if (!categoryFacet) return true;

        const hits = categoryFacet?.items?.find((item) => item.value == categoryId)?.count || 0;
        return hits > 0;
    };

    const toggleCategory = (categoryId: string | number) => {
        const isSelected = isCategoryOptionSelected(categoryId as string);
        if (isSelected) {
            setSelectedCategories([...selectedCategories].filter((id) => id != categoryId));
        } else {
            if (singleToggleMode) {
                setSelectedCategories([categoryId as string]);
            } else {
                setSelectedCategories([...selectedCategories, categoryId as string]);
            }
        }
    };

    const removeAllSelected = () => {
        setSelectedCategories([]);
    };

    return (
        <StyledProductCategoryListContainer>
            {categoryList && !!categoryList.length && (
                <StyledProductCategoryList>
                    <li key={`productCategoryList_all`}>
                        <ProductCategoryListButton
                            onClick={removeAllSelected}
                            isActive={selectedCategories.length == 0}
                            isDisabled={false}
                            value={'all'}
                        >
                            {translate('Kompan.Commerce.AllCategories').replace(
                                '[x]',
                                `${breadcrumb?.items?.at(-1)?.text}`,
                            )}
                        </ProductCategoryListButton>
                    </li>

                    {categoryList?.map((category) => {
                        const title = category.displayName?.values?.find(
                            (field) => field.language?.value == locale.toLowerCase(),
                        )?.text;
                        return (
                            title && (
                                <li key={`productCategoryList_${category.categoryId}`}>
                                    <ProductCategoryListButton
                                        onClick={(value) => toggleCategory(value)}
                                        value={category.categoryId}
                                        isDisabled={!isCategoryOptionAvailable(category.categoryId)}
                                        isActive={
                                            !!selectedCategories.includes(category.categoryId)
                                        }
                                    >
                                        {title}
                                    </ProductCategoryListButton>
                                </li>
                            )
                        );
                    })}
                </StyledProductCategoryList>
            )}
        </StyledProductCategoryListContainer>
    );
};
