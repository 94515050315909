export const getProductProperties = (lang: string | undefined = 'en-INT') => {
    const [locale, market] = lang.split('-');
    const langKey = `${locale.toLowerCase()}-${market.toUpperCase()}`;
    return {
        displayName: true,
        pricing: true,
        description: false,
        categoryPaths: true,
        assortments: false,
        disabled: true,
        allVariants: true,
        brand: false,
        category: false,
        dataKeys: [
            'UserCapacity',
            'IsInclusive',
            'MaxFallHeightValue',
            'MaxFallHeightUnit',
            'LengthValue',
            'LengthUnit',
            'WidthValue',
            'WidthUnit',
            'HeightValue',
            'HeightUnit',
            'SafetyZoneAreaLength',
            'SafetyZoneAreaWidth',
            'spaceMeasurementUnit',
            'AgeGroup',
            'ShowPriceOnKompan',
            'listPrice',
            'Activities',
            `AgeGroup_from_${langKey}`,
            // `AgeGroup_plp_from_${langKey}`,
            `MaxFallHeight_${langKey}`,
            `SafetyZoneArea_${langKey}`,
            'ProductPhotos',
        ],
    };
};
