import React, { useMemo } from 'react';
import { Button, DropdownItem, Sidepanel, useTracking } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import {
    Title,
    StyledScrollArea,
    StyledDrawerFooter,
    ClearContainer,
    SubmitContainer,
} from './styled';
import { AccordionFiltersBar } from '../AccordionFiltersBar';
import { useProductsStore } from '../../hooks';
import { Facet } from '$templates/blocks/components/M140ProductsList';
import { useMarket } from '~/shared/utils';
import {
    SpaceArea,
    handleBooleanFacet,
    handleMultiCheckboxFacet,
    handleMultiRangeFacet,
    handleSpaceFacet,
} from './utis/handleFacets';

export const FiltersDrawer = () => {
    const {
        showFiltersDrawer,
        facets,
        selectedFacets,
        setShowFiltersDrawer,
        clearAllFacets,
        toggleFacetOption,
        hiddenFacets,
        totalProducts,
        toggleBooleanFacetOption,
        setSelectOptions,
        removeFacetSelection,
        showLocalePrices,
    } = useProductsStore();
    const { translate } = useTranslation();
    const { trackCategoryFilter } = useTracking();
    const { culture } = useMarket();

    const closeDrawer = () => {
        setShowFiltersDrawer(false);
    };

    const onFacetChange = (
        event: React.ChangeEvent<HTMLInputElement> | undefined,
        facet: Facet,
        item?: DropdownItem,
        spaceArea?: SpaceArea,
    ) => {
        const isChecked = event?.target?.checked ?? false;

        switch (facet.displayType) {
            case 'BOOLEAN':
                handleBooleanFacet({
                    facet,
                    isChecked,
                    toggleBooleanFacetOption,
                    trackCategoryFilter,
                });
                break;

            case 'MULTI_CHECKBOX':
            case 'MULTI_CHECKBOX_PLAY_ACTIVITIES':
            case 'MULTI_CHECKBOX_WEB_GENERAL_TAGS':
                handleMultiCheckboxFacet({ facet, item, toggleFacetOption, trackCategoryFilter });
                break;

            case 'RANGE':
                console.warn('range facet not yet implemented');
                break;

            case 'MULTI_RANGE':
                handleMultiRangeFacet({ facet, item, setSelectOptions, trackCategoryFilter });
                break;

            case 'SPACE':
                handleSpaceFacet({
                    facet,
                    item,
                    spaceArea,
                    setSelectOptions,
                    removeFacetSelection,
                    trackCategoryFilter,
                });
                break;

            default:
                break;
        }
    };

    const visibleFacets = useMemo(() => {
        return facets?.filter((x) => !hiddenFacets.includes(x.attribute));
    }, [facets, hiddenFacets]);

    return (
        <Sidepanel
            culture={culture}
            maxWidth={675}
            open={showFiltersDrawer}
            onClose={closeDrawer}
            sidePadding={'large'}
            footerContent={
                <StyledDrawerFooter>
                    <ClearContainer>
                        <Button
                            onClick={clearAllFacets}
                            variant="Transparent"
                            disableHoverAnimation={true}
                        >
                            {translate('Kompan.Commerce.FiltersClearAll')}
                        </Button>
                    </ClearContainer>
                    <SubmitContainer>
                        <Button onClick={closeDrawer} variant="Red" disableHoverAnimation={true}>
                            {translate('Kompan.Commerce.SeeXResults').replace(
                                '[x]',
                                `${totalProducts}`,
                            )}
                        </Button>
                    </SubmitContainer>
                </StyledDrawerFooter>
            }
        >
            <StyledScrollArea>
                <Title variant="display3">{translate('Kompan.Commerce.Filters')}</Title>
                <AccordionFiltersBar
                    facets={visibleFacets}
                    selectedFacets={selectedFacets}
                    onFacetChange={onFacetChange}
                    isChild={true}
                    showLocalePrices={showLocalePrices || false}
                />
            </StyledScrollArea>
        </Sidepanel>
    );
};
