import { CallToAction, Text, KProductCard, Icon, useStructuredData } from '$shared/components';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect, useSearchParam } from 'react-use';
import { useSearchState } from '~/features/navigation/components/N25SearchBar';
import React, {
    memo,
    useCallback,
    useDeferredValue,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    KProductCardType,
    M140ProductListContentLayout,
    M140ProductListModule,
    TranslationKey,
} from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation';
import { getCurrentMarketPath } from '~/templates/pages/utils';
import { ModuleContainer } from '../ModuleContainer';
import { useIsSearchPage } from '../SearchResults/utils/useIsSearchPage';
import {
    ContinuousPagination,
    DropdownFiltersBar,
    RelewiseConnector,
    SelectedFacetDropdown,
    SelectedFilters,
    SimplePagination,
    useProductsStore,
    useProductsStoreUrlState,
} from './components';
import { productStoreUrlHelper } from './components/utils/productStoreUrlHelper';
import { PredefinedFilters, Product, SortFacetsType } from './model';
import {
    StyledHeadline,
    StyledHeadlineRow,
    StyledLayoutToggle,
    StyledLayoutToggleInput,
    StyledLayoutToggleOption,
    StyledNoResultsText,
    StyledProductGridItem,
    StyledProductOptions,
    StyledProductsContainer,
    StyledProductsGrid,
    StyledSelectedFacetsBar,
} from './styled';
import { useRelewiseTracking } from '~/shared/hooks/useRelewiseTracking/useRelewiseTracking';
import ListLayout from '$icons/list-layout.svg';
import CardLayout from '$icons/grid.svg';
import { ProductListItem } from './components/ProductListItem';
import { ProductListHeadline } from './components/ProductListHeadline';
import { ProductListQuoteForm } from './components/ProductListQuoteForm/ProductListQuoteForm';
import {
    calculateSafetyZoneDimensions,
    createFacetButton,
    dimensionsFilterType,
    isSafetyZoneAreaFacet,
} from './components/utils/handleSelectedFacetButtons';
import { usePage, usePageBaseUrl } from '~/templates/pages';
import { Frame, useFrame } from '~/shared/utils';
import { mapProductToBasicProps } from './components/utils/mapProductToBasicProps';
import { CompareOverview } from './components/compare';
import { ProductOptions } from './components/productOptions';
import { useIsDesktop } from '~/shared/hooks/useIsDesktop/useIsDesktop';
import { Notifications } from '~/shared/components/Notifications';
import { getActiveM140Categories } from './components/utils/getActiveM140Categories';
import { fetcher } from '~/lib/fetcher';
import { GetCountryAPIResponse } from '~/templates/pages/api/geoIp/getCountry/model';
import { GetCountryEndpointUrl } from '~/templates/pages/api/geoIp/getCountry';
export type M140ProductListProps = M140ProductListModule;

type ProductCardMemoProps = {
    product: Product;
    productPageUrl: string;
    frame: Frame;
    smallCard?: boolean;
    translate: (key: TranslationKey, fallback?: string) => string;
    showPrices?: boolean;
};

const ProductCardMemo = memo(
    ({
        product,
        productPageUrl,
        frame,
        smallCard: smallCard,
        translate,
        showPrices,
    }: ProductCardMemoProps) => {
        const componentProps = useMemo(() => {
            return mapProductToBasicProps({ product, productPageUrl, frame, translate });
        }, [product, productPageUrl, frame, translate]);

        return (
            <StyledProductGridItem key={`${product.productId}_${product?.displayName}`}>
                <KProductCard
                    hoverImageQuality={60}
                    type={KProductCardType.REGULAR}
                    smallCard={smallCard}
                    showPrices={showPrices}
                    {...componentProps}
                >
                    {product.productId && (
                        <StyledProductOptions>
                            <ProductOptions product={product} />
                        </StyledProductOptions>
                    )}
                </KProductCard>
            </StyledProductGridItem>
        );
    },
);

type ProductListItemMemoProps = {
    onQuoteButtonClick?: (product: Product) => void;
    product: Product;
    productPageUrl: string;
    showPrice: boolean;
};

const ProductListItemMemo = memo(
    ({ product, productPageUrl, onQuoteButtonClick, showPrice }: ProductListItemMemoProps) => {
        return (
            <ProductListItem
                product={product}
                productPageUrl={productPageUrl}
                onQuoteButtonClick={onQuoteButtonClick}
                showPrice={showPrice}
            />
        );
    },
);

export const M140ProductsList = ({
    paginationType = 'Continuous Loading',
    showOnlyProductsList = false,
    showPagination = true,
    productsPerPage,
    callToAction,
    pageElementIndex,
    headline,
    productCategoryIds,
    showUserFilters = false,
    showCategoryOverview = false,
    showEmptyCategories = false,
    initialState,
    inclusiveFilter,
    greenlineEditionFilter,
    keyUserGroupFromMinFilter,
    keyUserGroupFromMaxFilter,
    keyUserGroupToMinFilter,
    keyUserGroupToMaxFilter,
    playCapacityFromFilter,
    playCapacityToFilter,
    quickSupplyFilter,
    defaultLayout = 'Cards',
    id,
    ...rest
}: M140ProductListProps) => {
    const { query, replace } = useRouter();
    const {
        products,
        totalProducts,
        isSearching,
        pagination,
        setPagination,
        setActiveCategories,
        setProducts,
        facets,
        selectedFacets,
        setSelectedFacets,
        setSelectedCategories,
        removeFacetOption,
        clearAllFacets,
        selectedFacetsSort,
        setSelectedFacetsSort,
        showLocalePrices,
        setShowLocalePrices,
    } = useProductsStore();
    const { data: frame } = useFrame();
    const { setFacetsFromUrl, setCategoriesFromUrl } = useProductsStoreUrlState();
    const { getPageFromQuery } = productStoreUrlHelper();
    const productPageUrl = `/${getCurrentMarketPath()}/p`;
    const { setQuery } = useSearchState();
    const searchQuery = useSearchParam('search');
    const scrollTargetRef = useRef<HTMLSpanElement>(null);
    const [initRelewise, setInitRelewise] = useState(false);
    const [showSSRProducts, setShowSSRProducts] = useState(true);
    const [contentLayout, setContentLayout] = useState<M140ProductListContentLayout>(defaultLayout);
    const [activeProduct, setActiveProduct] = useState<Product | undefined>(undefined);
    const [showQuoteForm, setShowQuoteForm] = useState(false);
    const isDesktop = useIsDesktop();

    const filter: PredefinedFilters = {
        inclusive: inclusiveFilter,
        greenlineEdition: greenlineEditionFilter,
        quickSupply: quickSupplyFilter,
        keyUserGroupFromMin: keyUserGroupFromMinFilter,
        keyUserGroupFromMax: keyUserGroupFromMaxFilter,
        keyUserGroupToMin: keyUserGroupToMinFilter,
        keyUserGroupToMax: keyUserGroupToMaxFilter,
        playCapacityFrom: playCapacityFromFilter,
        playCapacityTo: playCapacityToFilter,
    };
    const { isSearchPage } = useIsSearchPage();
    const { trackProductCategory } = useRelewiseTracking();
    const pageBaseUrl = usePageBaseUrl();
    const { translate } = useTranslation();
    const { culture } = usePage();

    const { setListProductSchema, removeListProductSchema } = useStructuredData();

    useEffect(() => {
        if (products.length > 0 && selectedFacets.length === 0) {
            setListProductSchema({
                itemListElement: products.map((item, index) => ({
                    '@type': 'ListItem',
                    position: index + 1,
                    url: `${pageBaseUrl}${productPageUrl}/${item?.productId?.toLowerCase()}`,
                })),
            });
        } else {
            removeListProductSchema();
        }

        return () => {
            removeListProductSchema();
        };
    }, [products, selectedFacets]);

    useIsomorphicLayoutEffect(() => {
        const page = getPageFromQuery(query);

        if (searchQuery) {
            setQuery(searchQuery);
        }
        if (query.view_as) {
            setContentLayout(query.view_as as M140ProductListContentLayout);
        }
        if (query.sortKey && query.sortOrder) {
            setSelectedFacetsSort({
                sortKey: query.sortKey.toString(),
                sortOrder: query.sortOrder.toString() as SortFacetsType['sortOrder'],
            });
        }
        setShowSSRProducts(false);
        const computedActiveCategories = getActiveM140Categories(productCategoryIds);
        if (computedActiveCategories?.length) {
            setActiveCategories(computedActiveCategories);
            trackProductCategory(computedActiveCategories);
        } else {
            setActiveCategories([]);
        }

        setProducts(initialState?.results || []);
        setFacetsFromUrl();
        setCategoriesFromUrl();
        setPagination({
            ...pagination,
            ...{
                itemsPerPage: parseInt(productsPerPage),
                type: paginationType == 'Continuous Loading' ? 'continuous' : 'page',
                page: page,
            },
        });
        // Delay relewise Initializing to prevent fetching products before URLstate has been read.
        setTimeout(() => {
            setInitRelewise(true);
        });
        return () => {
            clearAllFacets();
            setSelectedFacets([]);
            setSelectedCategories([]);
        };
    }, []);

    const computedLayout: M140ProductListContentLayout = useMemo(() => {
        if (contentLayout === 'List' && isDesktop) {
            return 'List';
        }
        return 'Cards';
    }, [isDesktop, contentLayout]);

    const deferredLayout = useDeferredValue(computedLayout);

    const productItems = useMemo(() => {
        return showSSRProducts ? initialState?.results || [] : products;
    }, [products, initialState, showSSRProducts]);

    useEffect(() => {
        if (pagination.type == 'page' && scrollTargetRef.current && initRelewise) {
            scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [pagination.page]);

    // Array of all selected facets as extended DropdownItem
    const selectedFacetButtons = useMemo(() => {
        if (selectedFacets.length == 0) return [];

        let dimensionsFilter: dimensionsFilterType | undefined = undefined;

        const sortedSelectedFacets = [...selectedFacets]
            .sort((a, b) => a.attribute.localeCompare(b.attribute))
            .filter((item) => {
                if (!isSafetyZoneAreaFacet(item.attribute)) return true;

                dimensionsFilter = calculateSafetyZoneDimensions(item, dimensionsFilter);

                return false;
            });

        if (dimensionsFilter !== undefined) sortedSelectedFacets.push(dimensionsFilter);

        return sortedSelectedFacets
            .map((facet) => {
                const correspondingFacet = facets.find(
                    (facetData) => facetData.attribute == facet.attribute,
                );

                return createFacetButton(facet, correspondingFacet, dimensionsFilter, translate);
            })
            .reduce((flatten, arr) => [...flatten, ...arr]);
    }, [selectedFacets, facets]);

    const onSelectedFacetClick = (item: SelectedFacetDropdown) => {
        const { attribute, ...dropdown } = item;

        if (attribute === 'SafetyZoneDimensions') {
            const SafetyZoneAreaFacets = [...selectedFacets].filter((item) => {
                if (
                    item.attribute.startsWith('SafetyZoneAreaWidth') ||
                    item.attribute.startsWith('SafetyZoneAreaLength')
                ) {
                    return true;
                }
                return false;
            });

            SafetyZoneAreaFacets.forEach((item) => {
                const { attribute, items } = item;

                items.forEach((dropdown) => {
                    removeFacetOption(attribute, dropdown);
                });
            });
        } else {
            removeFacetOption(attribute, dropdown);
        }
    };

    const onClearAllClick = () => {
        clearAllFacets();
    };

    const onQuoteButtonClick = (product: Product) => {
        setActiveProduct(product);
        setShowQuoteForm(true);
    };

    const onSortingChange = useCallback(
        (newSortKey: string) => {
            const params = new URLSearchParams(window.location.search);
            const { sortKey, sortOrder } = selectedFacetsSort;

            if (newSortKey !== sortKey) {
                params.set('sortKey', newSortKey);
                params.set('sortOrder', 'asc');
                setSelectedFacetsSort({
                    sortKey: newSortKey,
                    sortOrder: 'asc',
                });

                params.delete('page');
            } else if (sortOrder === 'asc') {
                params.set('sortOrder', 'desc');

                setSelectedFacetsSort({
                    sortKey: newSortKey,
                    sortOrder: 'desc',
                });

                params.delete('page');
            } else {
                params.delete('sortKey');
                params.delete('sortOrder');

                params.delete('page');
                setSelectedFacetsSort({
                    sortKey: null,
                    sortOrder: null,
                });
            }

            replace(
                {
                    ...window.location,
                    search: params.toString(),
                },
                undefined,
                {
                    shallow: true,
                },
            );
        },
        [selectedFacetsSort],
    );

    const [showPrices, setShowPrices] = useState(
        productItems?.[0]?.data.ShowPriceOnKompan?.value === 'True' ? true : false,
    );

    useEffect(() => {
        const pricesAvailable =
            productItems?.[0]?.data.ShowPriceOnKompan?.value === 'True' ? true : false;

        if (pricesAvailable && showLocalePrices === null) {
            fetcher<GetCountryAPIResponse>(GetCountryEndpointUrl)
                .then((res) => res.json())
                .then((data) => {
                    if (
                        data.success &&
                        data?.data?.isoCode &&
                        culture === data?.data?.isoCode.toLowerCase()
                    ) {
                        setShowLocalePrices(true);
                        setShowPrices(true);
                    } else {
                        setShowLocalePrices(false);
                        setShowPrices(false);
                    }
                });
        } else {
            setShowPrices(showLocalePrices || false);
        }
    }, [productItems?.[0]?.data, culture]);

    const productList = useMemo(() => {
        if (deferredLayout == 'List') {
            return (
                <>
                    <ProductListHeadline
                        onSortingChange={onSortingChange}
                        sorting={selectedFacetsSort}
                        showPrice={showPrices}
                    />
                    {productItems?.map((product) => (
                        <ProductListItemMemo
                            onQuoteButtonClick={onQuoteButtonClick}
                            product={product}
                            productPageUrl={productPageUrl}
                            key={product.productId}
                            showPrice={showPrices}
                        />
                    ))}
                </>
            );
        }

        return (
            <>
                {productItems?.map((product) => (
                    <ProductCardMemo
                        product={product}
                        productPageUrl={productPageUrl}
                        key={product.productId}
                        frame={frame as Frame}
                        translate={translate}
                        smallCard={showOnlyProductsList}
                        showPrices={showPrices}
                    />
                ))}
            </>
        );
    }, [
        deferredLayout,
        isDesktop,
        productItems,
        productPageUrl,
        onSortingChange,
        showOnlyProductsList,
        showPrices,
    ]);

    const paginationToRender = useMemo(() => {
        return paginationType === 'Single Page' ? <SimplePagination /> : <ContinuousPagination />;
    }, [paginationType]);

    const onLayoutChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e?.target?.value as M140ProductListContentLayout;
            setContentLayout(value);
            const params = new URLSearchParams(window.location.search);
            if (value) {
                params.set('view_as', value);
            } else {
                params.delete('view_as');
            }

            if (value === 'Cards' && (selectedFacetsSort.sortKey || selectedFacetsSort.sortOrder)) {
                setSelectedFacetsSort({
                    sortKey: null,
                    sortOrder: null,
                });
                params.delete('sortKey');
                params.delete('sortOrder');
            }

            replace(
                {
                    ...window.location,
                    search: params.toString(),
                },
                undefined,
                {
                    shallow: true,
                },
            );
        },
        [setSelectedFacetsSort, setContentLayout, selectedFacetsSort],
    );

    const SelectedFiltersMemoized = () =>
        useMemo(
            () => (
                <SelectedFilters
                    filters={selectedFacetButtons}
                    onClick={onSelectedFacetClick}
                    onClearAll={onClearAllClick}
                />
            ),
            [selectedFacetButtons],
        );

    const ProductsComponent = useMemo(
        () => (
            <StyledProductsContainer>
                <StyledProductsGrid layout={deferredLayout} smallCard={showOnlyProductsList}>
                    {productList}

                    {!isSearching && initRelewise && products?.length == 0 && (
                        <StyledNoResultsText as="h2" variant="display4">
                            {isSearchPage
                                ? translate('Kompan.Search.NoProductResults')
                                : translate('Kompan.Commerce.NoProductsFound')}
                        </StyledNoResultsText>
                    )}

                    {isSearching && initRelewise && products?.length == 0 && (
                        <span>{translate('Kompan.Commerce.Loading')}</span>
                    )}
                </StyledProductsGrid>
                {showPagination && paginationToRender}
                {isDesktop && <CompareOverview />}
            </StyledProductsContainer>
        ),
        [
            productList,
            deferredLayout,
            isSearching,
            initRelewise,
            products,
            paginationToRender,
            isDesktop,
            showOnlyProductsList,
            showPagination,
        ],
    );

    if (showOnlyProductsList)
        return (
            <>
                {initRelewise && (
                    <RelewiseConnector useUrlState={true} predefinedFilters={filter} />
                )}
                {ProductsComponent}
            </>
        );

    return (
        <>
            <ModuleContainer hasGutter pageElementIndex={pageElementIndex} {...rest}>
                {initRelewise && (
                    <RelewiseConnector useUrlState={true} predefinedFilters={filter} />
                )}
                {(headline || callToAction?.url) && (
                    <StyledHeadlineRow>
                        {headline && <StyledHeadline variant="display2">{headline}</StyledHeadline>}
                        {callToAction?.url && <CallToAction callToAction={callToAction} />}
                    </StyledHeadlineRow>
                )}
                <span ref={scrollTargetRef} aria-hidden="true"></span>
                <DropdownFiltersBar
                    showUserFilters={showUserFilters}
                    showCategoryOverview={showCategoryOverview}
                    showEmptyCategories={showEmptyCategories}
                />
                <StyledSelectedFacetsBar>
                    <Text as="span" variant="body">
                        {showSSRProducts &&
                            translate('Kompan.Commerce.XProducts').replace(
                                '[x]',
                                `${initialState?.hits}`,
                            )}
                        {!showSSRProducts &&
                            !isSearching &&
                            translate('Kompan.Commerce.XProducts').replace(
                                '[x]',
                                `${totalProducts}`,
                            )}
                    </Text>
                    {selectedFacets?.some(
                        (selectedFacet) => selectedFacet.items && selectedFacet.items.length > 0,
                    ) && <SelectedFiltersMemoized />}
                    {isDesktop && (
                        <StyledLayoutToggle active={contentLayout == 'Cards' ? 'left' : 'right'}>
                            <StyledLayoutToggleOption
                                active={contentLayout == 'Cards'}
                                aria-label={translate('Kompan.Commerce.ShowAsCards')}
                            >
                                <StyledLayoutToggleInput
                                    type="radio"
                                    name={`${id}_contentLayout`}
                                    value="Cards"
                                    onChange={onLayoutChange}
                                    checked={computedLayout == 'Cards'}
                                />
                                <Icon>
                                    <CardLayout />
                                </Icon>
                            </StyledLayoutToggleOption>

                            <StyledLayoutToggleOption
                                active={contentLayout == 'List'}
                                aria-label={translate('Kompan.Commerce.ShowAsList')}
                            >
                                <StyledLayoutToggleInput
                                    type="radio"
                                    name={`${id}_contentLayout`}
                                    value="List"
                                    onChange={onLayoutChange}
                                    checked={computedLayout == 'List'}
                                />
                                <Icon>
                                    <ListLayout />
                                </Icon>
                            </StyledLayoutToggleOption>
                        </StyledLayoutToggle>
                    )}
                </StyledSelectedFacetsBar>
                {ProductsComponent}
                <ProductListQuoteForm
                    onClose={() => setShowQuoteForm(false)}
                    show={showQuoteForm}
                    activeProduct={activeProduct}
                />
            </ModuleContainer>
            <Notifications />
        </>
    );
};
